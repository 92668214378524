<template>
  <div>
    <router-view :store="data"></router-view>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>
